export const useBlocksStore = defineStore('blocks', {
	state: () => ({
		blocks: new Map(),

		icons: {
			'tree-palm': {
				keywords: [
					'holiday',
					'vacation',
					'annual leave',
					'vacay',
					'trip',
					'hols',
				],
				style: 'regular',
			},
			'rings-wedding': {
				keywords: ['wedding', 'engagement', 'bridal'],
				style: 'solid',
			},
			'person-breastfeeding': {
				keywords: ['maternity', 'paternity', 'parental', 'pregnan'],
				style: 'solid',
			},
			'hands-holding-heart': {
				keywords: [
					'funeral',
					'bereavement',
					'personal',
					'urn',
					'compassionate',
				],
				style: 'solid',
			},
		},

		default: {
			icon: 'ban',
			style: 'solid',
			label: 'Busy',
		},
	}),

	getters: {
		all(state) {
			return Array.from(state.blocks.values()).map((block) => {
				block.lastAccessed = Date.now();
				return block;
			});
		},

		getBlockById(state) {
			return (id) => {
				const block = state.blocks.get(id);
				block.lastAccessed = Date.now();
				return block;
			};
		},

		getOverlappingBlocks(state) {
			return (start, end, id) => {
				return state.all.filter((block) => {
					return (
						block.id !== id &&
						moment(block.startTime).isBefore(end) &&
						moment(block.endTime).isAfter(start)
					);
				});
			};
		},
	},

	actions: {
		setBlocks(newBlocks) {
			for (const newBlock of ensureArray(newBlocks)) {
				this.blocks.set(newBlock.id, { ...newBlock, lastAccessed: Date.now() });
			}
		},

		setLastAccessed(blocks) {
			for (const block of ensureArray(blocks)) {
				const blockId = typeof block === 'string' ? block : block.id;
				if (this.blocks.has(blockId)) {
					this.getBlockById(blockId);
				}
			}
		},

		async fetchBlocksFromRemote() {
			try {
				const records = await pb.collection('blocks').getFullList({
					sort: '-startTime',
				});
				this.setBlocks(records);
				return this.all;
			} catch (error) {
				return error;
			}
		},

		async addBlock(block) {
			try {
				const record = await pb.collection('blocks').create(block);
				this.setBlocks(record);
				return record;
			} catch (error) {
				return error;
			}
		},

		async updateBlock(block) {
			try {
				const record = await pb.collection('blocks').update(block.id, block);
				this.setBlocks(record);
				return record;
			} catch (error) {
				return error;
			}
		},

		async removeBlock(block) {
			try {
				const record = await pb.collection('blocks').delete(block.id);
				this.blocks.delete(block.id);
				return record;
			} catch (error) {
				return error;
			}
		},

		getBlocksForDate(date) {
			const d = moment(date);
			return this.all.filter((block) => {
				return (
					d.isSameOrAfter(block.startTime) && d.isSameOrBefore(block.endTime)
				);
			});
		},

		getIconForString(string) {
			const icon = Object.keys(this.icons).find((icon) =>
				this.icons[icon].keywords.some((keyword) =>
					String(string).toLowerCase().includes(keyword),
				),
			);

			const safeIcon = icon || this.default.icon;

			return {
				icon: safeIcon,
				style: this.icons[safeIcon]?.style || this.default.style,
			};
		},
	},
});
