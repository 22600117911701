<template>
	<Transition name="slide-up">
		<header
			class="box container"
			:class="{ 'is-nav-active': isNavigationOpen }"
			v-if="isHeaderVisible"
		>
			<nav class="navbar" role="navigation" aria-label="main navigation">
				<div class="navbar-brand">
					<div id="logo" class="navbar-item">
						<router-link to="/">
							<img src="/images/logo.svg" class="is-block" />
						</router-link>
					</div>

					<!--
					<div id="search" class="navbar-item is-expanded">
						<div class="field">
							<p class="control has-icons-left">
								<input type="search" class="input is-rounded" placeholder="Search for anything" />
								<span class="icon is-small is-left">
									<element-icon light icon="search" />
								</span>
							</p>
						</div>
					</div>
					-->

					<div
						id="user-menu"
						class="navbar-item has-dropdown is-hidden-touch"
						:class="{ 'is-active': isUserMenuOpen }"
						ref="userMenu"
					>
						<a
							class="navbar-link"
							@click.prevent="isUserMenuOpen = !isUserMenuOpen"
						>
							{{ user.data.firstName }}
						</a>

						<layout-user-menu
							class="navbar-dropdown is-right is-boxed is-clipped"
							@close="isUserMenuOpen = false"
						></layout-user-menu>
					</div>

					<a
						role="button"
						class="navbar-burger"
						:class="{ 'is-active': isNavigationOpen }"
						aria-label="menu"
						:aria-expanded="isNavigationOpen"
						@click="isNavigationOpen = !isNavigationOpen"
					>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</a>
				</div>

				<div class="navbar-menu" :class="{ 'is-active': isNavigationOpen }">
					<div class="navbar-start">
						<router-link to="/" class="navbar-item">
							<span class="icon-text">
								<span class="icon is-hidden-desktop">
									<element-icon size="lg" regular icon="clipboard" />
								</span>
								<span>Dashboard</span>
							</span>
						</router-link>

						<router-link to="/schedule" class="navbar-item">
							<span class="icon-text">
								<span class="icon is-hidden-desktop">
									<element-icon size="lg" regular icon="calendar-clock" />
								</span>
								<span>Schedule</span>
							</span>
						</router-link>

						<router-link to="/invoicing" class="navbar-item">
							<span class="icon-text">
								<span class="icon is-hidden-desktop">
									<element-icon size="lg" regular icon="file-invoice" />
								</span>
								<span>Invoicing</span>
							</span>
						</router-link>

						<!--
						<router-link to="/earnings" class="navbar-item">
							<span class="icon-text">
								<span class="icon is-hidden-desktop">
									<element-icon size="lg" solid icon="sterling-sign" />
								</span>
								<span>Earnings</span>
							</span>
						</router-link>

						<router-link to="/career" class="navbar-item">
							<span class="icon-text">
								<span class="icon is-hidden-desktop">
									<element-icon size="lg" regular icon="briefcase" />
								</span>
								<span>Career</span>
							</span>
						</router-link>

						<router-link to="/insights" class="navbar-item">
							<span class="icon-text">
								<span class="icon is-hidden-desktop">
									<element-icon size="lg" regular icon="chart-line" />
								</span>
								<span>Insights</span>
							</span>
						</router-link>
						-->
					</div>

					<div class="navbar-end is-hidden-desktop">
						<div class="navbar-item">
							<h1 class="title is-4">
								{{ user.data.firstName }} {{ user.data.lastName }}
							</h1>
							<p class="subtitle is-7">GPhC {{ user.data.gphcNumber }}</p>
						</div>

						<layout-user-menu></layout-user-menu>
					</div>
				</div>
			</nav>
		</header>
	</Transition>
</template>

<script setup>
import { watch } from 'vue';

/* Get route */
const route = useRoute();

/* Get user data */
const user = User();

/* Reference to user menu element */
const userMenu = ref(null);

/* Whether the entire header component should be shown */
const isHeaderVisible = ref(pb.authStore.isValid);

/* Whether mobile navigation is open */
const isNavigationOpen = ref(false);

/* Whether desktop user menu is open */
const isUserMenuOpen = ref(false);

/* Close user menu when clicked outside */
onClickOutside(userMenu, () => {
	isUserMenuOpen.value = false;
});

/* When the route changes */
watch(route, () => {
	/* Close all navs */
	isNavigationOpen.value = false;
	isUserMenuOpen.value = false;

	/* Check component visibility */
	isHeaderVisible.value = pb.authStore.isValid;
});
</script>

<style lang="scss" scoped>
header {
	padding: 0;
	margin-top: 1rem;
	box-shadow: $shadow-strong;

	position: fixed;
	width: 100%;
	top: 0;
	z-index: 999;

	@include touch {
		margin: 0;
		border-radius: 0;

		&.is-nav-active {
			bottom: 0;
			margin-bottom: 0;
			box-shadow: none;
			z-index: 9999999999;
		}
	}

	@include desktop-only {
		max-width: none;
		left: 2rem;
		right: 2rem;
		width: auto;
	}
}

.navbar {
	flex-direction: column;
	background: none;

	@include touch {
		.is-nav-active & {
			display: flex;
			flex-direction: column;
			height: 100%;
		}
	}

	/* Top bar */
	.navbar-brand {
		align-items: center;
		margin: 0;

		#logo {
			flex-grow: 1; /* While no search function */
			padding-left: 1.5rem;

			@include mobile {
				flex-grow: 1;

				/*
				.is-nav-active & {
					display: none;
				}
				*/
			}
		}

		/*
		#search {
			@include mobile {
				display: none;

				.is-nav-active & {
					display: flex;
				}
			}

			& > .field {
				flex-grow: 1;
			}

			input[type="search"] {
				&:not(:hover):not(:focus):not(:active) {
					border-color: transparent;
					box-shadow: none;
				}
			}
		}
		*/

		#user-menu {
			margin-left: 0.5rem;
			margin-right: 0.5rem;

			.navbar-link {
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
				border-radius: 3rem;
			}
		}

		.navbar-burger {
			margin-right: 0.5rem;
			width: 3rem;
			height: 3rem;
			border-radius: 1.5rem;
		}
	}

	/* Bottom bar */
	.navbar-menu {
		border-top: 1px solid $grey-lighter;
		margin: 0;
		border-bottom-left-radius: 1rem;
		border-bottom-right-radius: 1rem;
		overflow: hidden;
		box-shadow: none;

		@include touch {
			&.is-active {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
			}
		}
	}

	.navbar-start {
		flex-grow: 1;

		@include touch {
			overflow-y: auto;
			padding-bottom: 1rem;
		}

		.navbar-item {
			flex-grow: 1;
			flex-basis: 0;
			padding: 0.75rem;
			font-size: 0.8125rem;
			font-weight: 600;
			letter-spacing: 0.03em;
			text-align: center;
			justify-content: center;
			text-transform: uppercase;
			border-right: 1px solid $grey-lighter;

			transition-duration: 0.2s;
			transition-timing-function: ease-in-out;
			transition-property: color, background;

			&:last-child {
				border-right: 0;
			}

			@include touch {
				font-size: $size-7;
				text-align: left;
				padding: 0.75rem 1.5rem;
			}

			@include desktop {
				.icon-text {
					position: relative;

					&:after {
						content: '';
						position: absolute;
						bottom: 1px;
						left: 50%;
						transform: translateX(-50%);
						height: 2px;
						width: 0;
						background: $primary;
						border-radius: 2px;
						transition: width 0.15s ease-in-out;
					}
				}

				&.router-link-active {
					color: $text;
					background: rgba($primary, 0.06);

					.icon-text:after {
						width: 100%;
					}
				}
			}
		}
	}

	/* Mobile user bar */
	.navbar-end {
		border-top: 3px solid $white-ter;
		padding-top: 0.5rem;
		padding-bottom: 1rem;

		:deep(.navbar-item) {
			padding: 0.75rem 1.5rem;
		}
	}
}
</style>
