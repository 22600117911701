import { Model, useRepo } from 'pinia-orm';
import { DateCast } from 'pinia-orm/casts';
import { Invoice as InvoiceModel } from './Invoice.js';

const expand = ['location', 'location.chain', 'invoices'];

export class Shift extends Model {
	static entity = 'shifts';
	static primaryKey = 'id';

	static fields() {
		return {
			confirmed: this.boolean(true),
			endTime: this.attr(null),
			id: this.attr(null),
			invoices: this.hasMany(InvoiceModel, 'shift'),
			isInvoiceable: this.boolean(true),
			location: this.attr(null),
			lunch: this.number(0),
			mileage: this.number(0),
			mileageRate: this.number(0),
			mileageThreshold: this.number(0),
			paysLunch: this.boolean(false),
			paysMileage: this.boolean(false),
			rate: this.number(0),
			rateType: this.string('hour'),
			startTime: this.attr(null),
		};
	}

	static casts() {
		return {
			endTime: DateCast,
			startTime: DateCast,
		};
	}
}

const state = useRepo(Shift);

const methods = {
	/* Convert merged expands into ids */
	demergeExpands(shift) {
		for (const key of expand) {
			/* No need to worry about deep expands */
			if (!key.includes('.') && shift[key] && 'id' in shift[key]) {
				shift[key] = shift[key].id;
			}
		}

		return shift;
	},

	/* Merge expands in place of ids */
	mergeExpands(record) {
		if (record?.expand) {
			for (const key of Object.keys(record.expand)) {
				record[key] = this.mergeExpands(record.expand[key]);
			}

			delete record.expand;
		}

		return record;
	},

	/* Safely save 1 or more records */
	sync(record) {
		const records = ensureArray(record).map((item) => this.mergeExpands(item));
		return state.save(records);
	},

	/* Fetch shifts from remote */
	async fetchFromRemote() {
		const records = await pb.collection('shifts').getFullList({
			sort: '-startTime',
			expand: expand.join(','),
		});
		return this.sync(records);
	},

	async addShift(shift) {
		try {
			const record = await pb
				.collection('shifts')
				.create(this.demergeExpands(shift), {
					expand: expand.join(','),
					requestKey: shift.requestKey || shift.id,
				});
			return this.sync(record);
		} catch (error) {
			return error;
		}
	},

	async updateShift(shift) {
		try {
			const record = await pb
				.collection('shifts')
				.update(shift.id, this.demergeExpands(shift), {
					expand: expand.join(','),
					requestKey: shift.requestKey || shift.id,
				});
			return this.sync(record);
		} catch (error) {
			return error;
		}
	},

	async removeShift(shift) {
		try {
			await pb
				.collection('shifts')
				.delete(shift.id, { requestKey: shift.requestKey || shift.id });
			return state.destroy(shift.id);
		} catch (error) {
			return error;
		}
	},

	getShiftById(id) {
		/* eslint-disable-next-line unicorn/no-array-callback-reference */
		return state.with('invoices').find(id);
	},

	getShiftsByDate(date) {
		return state
			.orderBy('startTime', 'asc')
			.get()
			.filter((shift) => moment(shift.startTime).isSame(moment(date), 'day'));
	},

	getShiftsByWeek(date) {
		return state
			.orderBy('startTime', 'asc')
			.get()
			.filter((shift) => moment(shift.startTime).isSame(moment(date), 'week'));
	},

	getShiftsByMonth(date) {
		return state
			.orderBy('startTime', 'asc')
			.get()
			.filter((shift) => moment(shift.startTime).isSame(moment(date), 'month'));
	},

	getOverlappingShifts(start, end, id) {
		return state.all().filter((shift) => {
			return (
				shift.id !== id &&
				moment(shift.startTime).isBefore(end) &&
				moment(shift.endTime).isAfter(start)
			);
		});
	},

	pastShifts() {
		return state
			.all()
			.filter((shift) => moment(shift.endTime).isBefore(moment()));
	},

	/* No invoice exists */
	uninvoicedShifts() {
		return state
			.doesntHave('invoices')
			.get()
			.filter((shift) => moment(shift.endTime).isBefore(moment()));
	},

	/* Invoice created, but not sent */
	getUnsent() {
		return state
			.whereHas('invoices', (query) => {
				query.where('sent', false).where('paid', false);
			})
			.get()
			.filter((shift) => moment(shift.endTime).isBefore(moment()));
	},

	getUnsentByMonth(date) {
		return state
			.getUnsent()
			.filter((shift) => moment(shift.startTime).isSame(moment(date), 'month'));
	},

	/* Invoice sent, but not paid */
	getUnpaid() {
		return state
			.whereHas('invoices', (query) => {
				query.where('sent', true).where('paid', false);
			})
			.get()
			.filter((shift) => moment(shift.endTime).isBefore(moment()));
	},

	getUnpaidByMonth(date) {
		return state
			.getUnpaid()
			.filter((shift) => moment(shift.startTime).isSame(moment(date), 'month'));
	},

	/* Invoice paid */
	getPaid() {
		return state
			.whereHas('invoices', (query) => {
				query.where('sent', true).where('paid', true);
			})
			.get()
			.filter((shift) => moment(shift.endTime).isBefore(moment()));
	},

	getPaidByMonth(date) {
		return state
			.getPaid()
			.filter((shift) => moment(shift.startTime).isSame(moment(date), 'month'));
	},

	getPaidByYear(date) {
		return state
			.getPaid()
			.filter((shift) => moment(shift.startTime).isSame(moment(date), 'year'));
	},

	/* Shift meta */

	getShiftLength(shift) {
		const difference = moment(shift.endTime).diff(moment(shift.startTime));
		const duration = moment.duration(difference);

		if (shift.lunch && !shift.paysLunch) {
			duration.subtract(shift.lunch, 'minutes');
		}

		const hours = duration.asHours();

		return hours > 0 ? hours : 0;
	},

	getShiftCappedMileage(shift) {
		/* Threshold mileage, if appropriate */
		const mileageLessThreshold =
			Number(shift.mileage) - Number(shift.mileageThreshold);

		/* Return number of billable miles */
		return shift.mileageThreshold
			? mileageLessThreshold < 0
				? 0
				: mileageLessThreshold
			: Number(shift.mileage);
	},

	getShiftMileageRate(shift) {
		/* Process and return mileage rate */
		/* TODO: 45p fallback should take annual mileage into account */
		return shift.mileageRate ? Number(shift.mileageRate) / 100 : 0.45;
	},

	getShiftMileagePrice(shift) {
		/* Work out mileage rate */
		const mileageRate = this.getShiftMileageRate(shift);

		/* Work out billable miles */
		const cappedMileage = this.getShiftCappedMileage(shift);

		/* Calculate appropriate mileage */
		return shift.mileage && shift.paysMileage ? cappedMileage * mileageRate : 0;
	},

	getShiftTotalPrice(shift) {
		/* Get shift base pay */
		const hours = this.getShiftLength(shift) || 0;
		const basepay = shift.rateType === 'hour' ? hours * shift.rate : shift.rate;

		/* Get mileage */
		const mileage = this.getShiftMileagePrice(shift);

		/* Calculate final price */
		return basepay + mileage;
	},

	getShiftState(shift) {
		if (moment().isSameOrAfter(shift.startTime)) {
			if (moment().isSameOrBefore(shift.endTime)) {
				return 'active';
			}

			return 'past';
		}

		return 'future';
	},
};

Object.assign(state, methods);

export default state;
