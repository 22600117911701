export const useAppStore = defineStore('app', {
	state: () => ({
		isLoading: false,

		invoicing: {
			highlightedColumn: null,
		},
	}),

	actions: {
		beginLoading() {
			this.isLoading = true;
		},
		finishLoading() {
			this.isLoading = false;
		},
		setInvoicingHighlightedColumn(column) {
			this.invoicing.highlightedColumn = column;
		},
	},
});
