/* eslint-disable import/newline-after-import, import/order, import/first */

/* Load support chat */

import chat from 'js/config/chat.js';
chat();

/* Create Vue app */

import { createApp } from 'vue';
const app = createApp(App);

/* Set up error monitoring */

import monitoring from 'js/config/monitoring.js';
monitoring(app);

/* Create store */

import { createPinia } from 'pinia';
import { createORM } from 'pinia-orm';

const pinia = createPinia().use(createORM());
app.use(pinia);

/* Create router */

import router from 'js/config/router.js';
app.use(router(pb));

/* Add icons */

import icons from 'js/config/icons.js';
icons(app);

/* Load plugins */

import plugins from 'js/config/plugins.js';
plugins(app);

/* Load moment */

import loadMoment from 'js/config/moment.js';
loadMoment();

/* Load initial app */

import App from './components/App.vue';
app.mount('#app');
