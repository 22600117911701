import { Model, useRepo } from 'pinia-orm';

export class Invoice extends Model {
	static entity = 'invoices';
	static primaryKey = 'id';

	static fields() {
		return {
			billableHours: this.number(0),
			id: this.attr(null),
			mileage: this.number(0),
			mileageRate: this.number(0),
			paid: this.boolean(false),
			rate: this.number(0),
			sent: this.boolean(false),
			shift: this.attr(null),
		};
	}
}

const state = useRepo(Invoice);

const methods = {
	/* Fetch invoices from remote */
	async fetchFromRemote() {
		const records = await pb.collection('invoices').getFullList({
			sort: '-created',
		});

		return state.save(records);
	},

	async addInvoice(invoice) {
		try {
			const record = await pb
				.collection('invoices')
				.create(invoice, { requestKey: invoice.requestKey });
			return state.save(record);
		} catch (error) {
			return error;
		}
	},

	async updateInvoice(invoice) {
		try {
			const record = await pb
				.collection('invoices')
				.update(invoice.id, invoice, {
					requestKey: invoice.requestKey || invoice.id,
				});
			return state.save(record);
		} catch (error) {
			return error;
		}
	},

	async removeInvoice(invoice) {
		try {
			await pb
				.collection('invoices')
				.delete(invoice.id, { requestKey: invoice.requestKey || invoice.id });
			return state.destroy(invoice.id);
		} catch (error) {
			return error;
		}
	},

	async createInvoiceFromShift(shift) {
		return await this.addInvoice({
			user: User().data.id,
			rate: shift.rate,
			mileage: shift.mileageThreshold
				? Math.max(shift.mileage - shift.mileageThreshold, 0)
				: shift.mileage,
			mileageRate: shift.mileageRate,
			billableHours: Shifts.getShiftLength(shift),
			sent: false,
			paid: false,
			requestKey: shift.id,
		});
	},

	setInvoiceStatus(invoiceId, status) {
		/* eslint-disable-next-line unicorn/no-array-callback-reference */
		const invoice = state.find(invoiceId);

		invoice.paid = status === 'paid';
		invoice.sent = status !== 'unsent';

		return this.updateInvoice(invoice);
	},
};

Object.assign(state, methods);

export default state;
